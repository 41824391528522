import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import MyProgress from './MyProgress';
import MyReferrals from './MyReferrals';
import { getActions, withGlobal } from '../../../../global';
import { GlobalState } from '../../../../global/types';
import { pick } from '../../../../util/iteratees';
import Loading from '../../../ui/Loading';
import { LeftColumnContent } from '../../../../types';
import useHistoryBack from '../../../../hooks/useHistoryBack';
import useLastCallback from '../../../../hooks/useLastCallback';

export enum ETab {
  progress,
  referrals,
}

type StateProps = Pick<GlobalState, 'loyalty' | 'currentUserId'>;

const LoyaltyProgram: FC<StateProps> = ({ loyalty, currentUserId }) => {
  const {
    getReferralCode,
    getUserReferralProgram,
    getLoyaltyBonusDataWithSum,
    setLeftScreen,
    setMiddleScreen,
  } = getActions();
  const [activeTab, setActiveTab] = useState<ETab>(ETab.progress);
  const { leftScreen, middleScreen } = window.history.state || {};

  const handleGoBack = useCallback(() => {
    setLeftScreen({ screen: leftScreen });
    setMiddleScreen({ screen: middleScreen });
  }, [leftScreen, middleScreen]);

  useHistoryBack({
    isActive: true,
    onBack: handleGoBack,
  });

  useEffect(() => {
    getReferralCode();
    getLoyaltyBonusDataWithSum();
  }, []);

  if (!loyalty) return <Loading />;

  return (
    <>
      {activeTab === ETab.progress ? (
        <MyProgress
          loyalty={loyalty}
          setTab={setActiveTab}
          currentUserId={currentUserId!}
        />
      ) : (
        <MyReferrals loyalty={loyalty} setTab={setActiveTab} />
      )}
    </>
  );
};

export default memo(
  withGlobal(
    (global): StateProps => pick(global, ['loyalty', 'currentUserId'])
  )(LoyaltyProgram)
);
